import styled from 'styled-components';
import { queries } from '../../utils/mediaQueries';

export const Container = styled.div`
  text-align: center;
  font-size: 30px;
  letter-spacing: 0;
  max-width: 800px;
  margin: 0 auto;
  display: block;
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  & p {
    line-height: 36px;
  }

  @media ${queries.sm} {
    max-width: 100%;
    padding: 0px 50px 30px 60px;
    font-size: 18px;
    & p {
      line-height: 26px;
    }
  }

  &:before {
    content: url(/images/quotes-left.svg);
    position: absolute;
    left: -70px;

    @media ${queries.md} {
      left: 0px;
      top: -20px;
      -webkit-transform: scale(0.5);
      transform: scale(0.5);
    }
  }

  &:after {
    content: url(/images/quotes-right.svg);
    position: absolute;
    right: -70px;
    bottom: 45px;

    @media ${queries.md} {
      position: absolute;
      right: 0;
      bottom: 10px;
      -webkit-transform: scale(0.5);
      transform: scale(0.5);
    }
  }

  ${({ pageDesign }) => {
    switch (pageDesign) {
      case 'design2':
        return `
          max-width: 1150px;
          padding-left: 0px;
          padding-right: 0px;
          &:before {
            left: 0!important;
          }
          &:after {
            right: 0!important;
          }

          @media ${queries.md} {
            padding: 20px 20px 40px 20px;
            &:before {
              left: -10px!important;
            }
            &:after {
              right: -10px!important;
            }
          }

        `;
      default:
        return ``;
    }
  }}

  ${({ designVersion }) => {
    switch (designVersion) {
      case 'version2':
        return `
            &:before {
              content: url(/images/quotes-left2.svg);
              width: 106px;
              height: 251px;
              left: -80px;
              @media ${queries.md} {
                left: 0px;
                top: -70px;
              }
            }
            &:after {
              content: url(/images/quotes-right2.svg);
              width: 106px;
              height: 251px;
              right: -80px;
              bottom: 10px;
              @media ${queries.md} {
                right: 0;
                bottom: -40px;
              }
            }
            `;
      default:
        return ``;
    }
  }}
`;

export const Content = styled.div`
  padding: 0 40px;
  @media ${queries.sm} {
    padding: 0 20px;
  }

  ${({ pageDesign }) => {
    switch (pageDesign) {
      case 'design2':
        return `
         max-width: 88%;
         margin: 0 auto;
        `;
      default:
        return ``;
    }
  }}
`;
